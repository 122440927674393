/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import React, {
  Fragment, useEffect, useRef, useState,
} from "react";
import {
  VideoRoomInfo, VideoRoomParameters, useVideo,
} from "@audacia-hq/shared/contexts";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo, faVideoSlash, faMicrophone, faMicrophoneSlash, faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { Trans } from "react-i18next";
import clsx from "clsx";

import Button from "../Button";
import VolumeMeter from "../audio/VolumeMeter";
import VolumeActivityBadge from "../audio/VolumeActivityBadge";
import DevicePicker from "../form/DevicePicker";
import useNavigatorMediaDevices from "../hooks/useNavigatorMediaDevices";
import AlertModal from "../modal/AlertModal";

interface Props {
  videoRoom: VideoRoomInfo;
  userPlaceholder?: React.ReactNode
  onReady?: () => void;
  showCancel?: boolean;
  helpLink?: string;
}

export const VideoReadyUpModal: React.FC<Props> = ({
  videoRoom, userPlaceholder, onReady, showCancel, helpLink,
}) => {
  const {
    t, namespace, onUserReady, userSettings, closeVideoRoom, pushTelemetryEvent, forceCamera,
  } = useVideo();
  const [closeConfirm, setCloseConfirm] = useState<boolean>(false);

  const { requestMediaStream, states, devices } = useNavigatorMediaDevices(["AUDIO", "VIDEO"]);
  const statesRef = useRef(states);
  const [selectedMicrophone, setSelectedMicrophone] = useState<string>(userSettings?.audioDevice);
  const [selectedCamera, setSelectedCamera] = useState<string>(forceCamera
    ? userSettings?.videoDevice
    : userSettings?.videoDevice || null);

  const [videoStream, setVideoStream] = useState<MediaStream>();

  const [audioToggle, setAudioToggle] = useState<boolean>(typeof userSettings?.audioToggle !== "undefined"
    ? userSettings.audioToggle : true);
  const [videoToggle, setVideoToggle] = useState<boolean>(typeof userSettings?.videoToggle !== "undefined"
    ? userSettings.videoToggle : true);

  const videoRef = useRef(null);

  const swapVideoStream = (stream?: MediaStream) => {
    setVideoStream(stream);
  };

  const pickMicrophone = (deviceId: string) => {
    setSelectedMicrophone(deviceId);
    pushTelemetryEvent({
      event: "DEVICE_CHANGED",
      data: {
        mediaType: "AUDIO",
        origin: "ReadyUpModal",
        videoRoomUid: videoRoom.videoRoomUid,
        deviceId,
        permissionState: statesRef.current["AUDIO"],
      },
    });
  };

  const pickCamera = (deviceId: string) => {
    setSelectedCamera(deviceId);
    pushTelemetryEvent({
      event: "DEVICE_CHANGED",
      data: {
        mediaType: "VIDEO",
        origin: "ReadyUpModal",
        videoRoomUid: videoRoom.videoRoomUid,
        deviceId,
        permissionState: statesRef.current["VIDEO"],
      },
    });
  };

  useEffect(() => {
    statesRef.current = states;
    pushTelemetryEvent({
      event: "DEVICE_PERMISSIONS_CHANGED",
      data: {
        origin: "ReadyUpModal",
        videoRoomUid: videoRoom.videoRoomUid,
        permissionState: states,
        devices,
      },
    });
  }, [states, devices]);

  useEffect(() => {
    if (!videoToggle || selectedCamera === null) {
      swapVideoStream(undefined);
      return;
    }
    if (videoStream) {
      videoStream?.getTracks().forEach((track) => track.stop());
    }
    if (states["VIDEO"] === "granted") requestMediaStream("VIDEO", selectedCamera).then(swapVideoStream);
  }, [selectedCamera, videoToggle, states["VIDEO"]]);

  useEffect(() => {
    if (videoRef?.current) {
      videoRef.current.srcObject = videoStream;
    }
    return () => {
      videoStream?.getTracks().forEach((track) => track.stop());
    };
  }, [videoStream]);

  const handleJoinVideoRoom = (parameters: VideoRoomParameters) => {
    onUserReady(videoRoom, parameters);
    if (onReady) onReady();
  };

  return (
    <Transition.Root show as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all overflow-hidden">
                <div className="w-full p-3 md:p-6">
                  <div className="flex flex-col md:flex-row gap-4">
                    <div
                      className="w-full sm:w-80 aspect-square mx-auto rounded-xl bg-black relative flex items-center justify-center overflow-hidden"
                      style={{
                        boxShadow: "0px 0px 0px 3px #00000040",
                      }}
                    >
                      {videoStream && videoToggle
                        ? (
                          <video
                            ref={videoRef}
                            autoPlay
                            muted
                            className="h-full w-full object-cover"
                          />
                        )
                        : userPlaceholder}
                      <div className="absolute inset-x-0 bottom-4 flex flex-row w-full justify-center gap-4">
                        <div
                          className="rounded-full bg-zinc-100 h-10 w-10 flex items-center justify-center cursor-pointer"
                          onClick={() => setAudioToggle((prev) => !prev)}
                        >
                          {audioToggle
                            ? <FontAwesomeIcon icon={faMicrophone} className="text-black" />
                            : <FontAwesomeIcon icon={faMicrophoneSlash} className="text-red-600" />}
                        </div>
                        <div
                          className={clsx(
                            "rounded-full h-10 w-10 flex items-center justify-center",
                            selectedCamera === null ? "cursor-not-allowed bg-white/70" : "bg-zinc-100 cursor-pointer",
                          )}
                          onClick={selectedCamera === null ? () => ({}) : () => setVideoToggle((prev) => !prev)}
                        >
                          {(videoToggle && selectedCamera !== null)
                            ? <FontAwesomeIcon icon={faVideo} className="text-black" />
                            : (
                              <FontAwesomeIcon
                                icon={faVideoSlash}
                                className={clsx(
                                  selectedCamera === null ? "text-black/40" : "text-red-600",
                                )}
                              />
                            )}
                        </div>
                      </div>
                      <div className="absolute top-3 right-3 bg-green-600 rounded-full">
                        <VolumeMeter
                          audioSource={{ deviceId: states["AUDIO"] === "granted" ? selectedMicrophone : undefined }}
                          muted={!audioToggle}
                        >
                          {({ volume }) => (
                            <VolumeActivityBadge volume={volume} className="bg-blue-600 h-7 w-7 p-[2px]" />
                          )}
                        </VolumeMeter>
                      </div>
                    </div>

                    <div className="w-full md:w-[360px] flex flex-col gap-4 md:gap-8">
                      <div className="flex flex-col gap-4 md:gap-2">

                        <div className="text-sm md:text-xl text-center md:text-left font-bold leading-6">
                          {t("video.readyUp.title")}
                        </div>
                        <div className="text-sm text-center md:text-left text-gray-500 leading-6">
                          {videoRoom.participants.map((p) => {
                            if (p.isMe) return null;
                            return p.online
                              ? <div key={p.userUid}>{t("video.readyUp.interlocutor.isConnected", { pseudo: p.name })}</div>
                              : <div key={p.userUid}>{t("video.readyUp.interlocutor.isNotConnected", { pseudo: p.name })}</div>;
                          })}
                          <div>{t("video.readyUp.instructions")}</div>
                        </div>
                      </div>

                      {(
                        !["granted", "unknown"].includes(states["AUDIO"])
                        || (forceCamera && !["granted", "unknown"].includes(states["VIDEO"]))
                      // || (
                      //   (forceCamera || selectedCamera)
                      // // && !["granted", "unknown", "no-device", "denied"].includes(states["VIDEO"])
                      // )
                      )
                        ? (
                          <div className="pl-3 pr-4 py-2 bg-red-50 border-red-600 border-l-[6px] rounded flex flex-row gap-2">
                            <div>
                              <FontAwesomeIcon icon={faTriangleExclamation} className="text-red-600 relative top-[-2px]" />
                            </div>
                            <div className="text-sm text-red-700">
                              <Trans
                                ns={namespace}
                                i18nKey="video.readyUp.permissionsError"
                                components={[
                                  <span>
                                    {states["AUDIO"] !== "granted" && t("common:device.microphone")}
                                    {states["AUDIO"] !== "granted" && !["granted", "no-device"].includes(states["VIDEO"]) && (
                                      ` ${t("common:and")} `
                                    )}
                                    {!["granted", "no-device"].includes(states["VIDEO"]) && t("common:device.camera")}
                                  </span>,
                                  <a className="underline" href={helpLink} target="_blank" rel="noreferrer">click here</a>,
                                ]}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex-col gap-3 text-sm">
                            <DevicePicker
                              mediaType="AUDIO"
                              initialValue={selectedMicrophone}
                              devices={devices["AUDIO"]}
                              onChange={pickMicrophone}
                            />
                            <DevicePicker
                              mediaType="VIDEO"
                              initialValue={forceCamera ? selectedCamera : selectedCamera || null}
                              nullLabel={forceCamera ? undefined : t("video.noCamera")}
                              devices={devices["VIDEO"]}
                              onChange={pickCamera}
                            />
                          </div>
                        )}

                    </div>

                  </div>
                </div>

                <div className="bg-gray-50 w-full px-3 py-4 flex flex-col md:flex-row-reverse gap-y-2 md:gap-x-2">
                  <Button
                    theme="primary"
                    disabled={!["granted", "unknown"].includes(states["AUDIO"]) || !selectedMicrophone
                      || (forceCamera && (!selectedCamera || !["granted", "unknown"].includes(states["VIDEO"])))
                      || (!forceCamera && selectedCamera && !["granted", "unknown"].includes(states["VIDEO"]))}
                    onClick={() => handleJoinVideoRoom({
                      videoDevice: selectedCamera, audioDevice: selectedMicrophone, videoToggle, audioToggle,
                    })}
                    size="medium"
                    className="w-full md:w-auto"
                  >
                    {t("video.readyUp.join")}
                  </Button>
                  {showCancel && (
                    <>
                      <Button
                        theme="simple"
                        data-pw="videoFinish"
                        size="medium"
                        className="w-full md:w-auto"
                        onClick={() => setCloseConfirm(true)}
                      >
                        {t("video.refuseVideoIncoming")}
                      </Button>
                      <AlertModal
                        open={closeConfirm}
                        title={t("video.finishVideoAlertTitle")}
                        validateLabel={t("video.refuseVideoIncoming")}
                        cancelLabel={t("common:cancel")}
                        variant="warning"
                        onValidate={() => {
                          closeVideoRoom();
                          setCloseConfirm(false);
                          pushTelemetryEvent({
                            event: "VIDEO_REFUSED",
                            data: {
                              origin: "ReadyUpModal",
                              videoRoomUid: videoRoom.videoRoomUid,
                              permissionState: statesRef.current,
                            },
                          });
                        }}
                        onClose={() => setCloseConfirm(false)}
                      >
                        <div>{t("video.finishVideoAlertContent", { pseudo: videoRoom?.participants?.find((p) => !p.isMe)?.name || "" })}</div>
                      </AlertModal>
                    </>
                  )}

                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>

    </Transition.Root>
  );
};
