import {
  useState, useEffect,
} from "react";

export type MediaDeviceType = "AUDIO" | "VIDEO";
export type PermissionState = "unknown" | "granted" | "denied" | "prompt" | "no-device";

const mapUserMedia = (mediaType: MediaDeviceType) => {
  switch (mediaType) {
    case "AUDIO":
    case "VIDEO":
      return mediaType.toLowerCase();
    default:
      return undefined;
  }
};

const mapPermissionName = (mediaType: MediaDeviceType) => {
  switch (mediaType) {
    case "AUDIO": return "microphone";
    case "VIDEO": return "camera";
    default:
      return undefined;
  }
};

const requestSpecificMediaPermission = async (mediaType: MediaDeviceType): Promise<{
  state: PermissionState, devices: MediaDeviceInfo[]
}> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ [mapUserMedia(mediaType)]: true });
    const devicesList = await navigator.mediaDevices.enumerateDevices();
    stream.getTracks().forEach((track) => track.stop());
    return { state: "granted", devices: devicesList.filter((device) => device.kind === `${mapUserMedia(mediaType)}input`) };
  } catch (err) {
    return { state: "denied", devices: [] };
  }
};

const requestGenericMediaPermission = async (mediaTypes: MediaDeviceType[]): Promise<{
  state: PermissionState, devices: MediaDeviceInfo[]
}[]> => {
  try {
    const query = mediaTypes.reduce((acc, mediaType) => {
      const userMediaType = mapUserMedia(mediaType);
      if (!userMediaType) return acc;
      return { ...acc, [userMediaType]: true };
    }, {});
    const stream = await navigator.mediaDevices.getUserMedia(query);
    const devicesList = await navigator.mediaDevices.enumerateDevices();
    stream.getTracks().forEach((track) => track.stop());
    return mediaTypes.map((t) => ({
      state: "granted" as const,
      devices: devicesList.filter((device) => device.kind === `${mapUserMedia(t)}input`),
    }));
  } catch (err) {
    // console.log("requestGenericMediaPermission", err);
    if (err.name === "NotFoundError") throw err;
    return mediaTypes.map(() => ({
      state: "denied" as const,
      devices: [],
    }));
  }
};

const fetchDevices = async (mediaTypes: MediaDeviceType[]): Promise<MediaDeviceInfo[][]> => {
  try {
    const devicesList = await navigator.mediaDevices.enumerateDevices();
    const devices = mediaTypes.map((mediaType) => {
      const kind = mapUserMedia(mediaType);
      if (!kind) return [];
      return devicesList.filter((device) => device.kind === `${kind}input`);
    });
    return devices;
  } catch (err) {
    return mediaTypes.map(() => []);
  }
};

const fetchPermissionObjects = async (mediaTypes: MediaDeviceType[], permissionChangeHandler): Promise<(PermissionStatus | null)[]> => {
  try {
    const permissionObjects = await Promise.all(mediaTypes.map((mediaType) => {
      const permissionName = mapPermissionName(mediaType);
      if (!permissionName) return null;
      return navigator.permissions.query({ name: permissionName as unknown as any })
        .then((permission) => {
          // eslint-disable-next-line no-param-reassign
          permission.onchange = permissionChangeHandler(mediaType);
          // permission.addEventListener("change", permissionChangeHandler(mediaType));
          return permission;
        })
        .catch((err) => null);
    }));
    return permissionObjects;
  } catch (err) {
    // console.log(err);
    return mediaTypes.map(() => null);
  }
};

// const requestMediaPermissions = async (mediaTypes: MediaDeviceType[], permissionChangeHandler): Promise<{
//   states: PermissionState[];
//   devices: MediaDeviceInfo[][];
//   permissionObjects: (PermissionStatus | null)[];
// }> => ({
//   states: await requestGenericMediaPermission(mediaTypes),
//   devices: await fetchDevices(mediaTypes),
//   permissionObjects: await fetchPermissionObjects(mediaTypes, permissionChangeHandler),
// });

interface mediaTypeMap<T> {
  [name: string]: T;
}

const useNavigatorMediaDevices = (mediaTypes: MediaDeviceType[]) => {
  const [permissionObjects, setPermissionObjects] = useState<mediaTypeMap<PermissionStatus | null>>(
    mediaTypes.reduce((acc, name) => ({ ...acc, [name]: null }), {}),
  );
  const [states, setStates] = useState<mediaTypeMap<PermissionState>>(
    mediaTypes.reduce((acc, name) => ({ ...acc, [name]: "unknown" as const }), {}),
  );
  const [devices, setDevices] = useState<mediaTypeMap<MediaDeviceInfo[]>>(
    mediaTypes.reduce((acc, name) => ({ ...acc, [name]: [] }), {}),
  );

  const handleDeviceChange = async () => {
    try {
      const devicesList = await navigator.mediaDevices.enumerateDevices();

      if (devicesList) {
        const devices = mediaTypes.map((mediaType) => {
          const kind = mapUserMedia(mediaType);
          if (!kind) return [];
          return devicesList.filter((device) => device.kind === `${kind}input`);
        });
        setDevices(devices.reduce((acc, d, i) => ({ ...acc, [mediaTypes[i]]: d }), {}));
      }
    } catch (err) {
      // console.log("EnumerateDevices error: ", err);
    }
  };

  const reloadDevices = (mediaType: MediaDeviceType) => {
    navigator.mediaDevices.enumerateDevices().then((devicesList) => {
      if (devicesList) {
        const devices = devicesList.filter((device) => device.kind === `${mapUserMedia(mediaType)}input`);
        setDevices((prev) => ({ ...prev, [mediaType]: devices }));
        // check if empty deviceId because of safari, empty label because of firefox...
        if (devices.some((d) => d.deviceId === "" || d.label === "")) {
          setStates((prev) => ({ ...prev, [mediaType]: "denied" as const }));
        }
      }
    });
  };

  const onPermissionChange = (name: MediaDeviceType) => (event) => {
    if (event.type !== "change") return;
    const state = (event.target as unknown as any)?.state;
    if (state) {
      setStates((prev) => ({ ...prev, [name]: state }));
      if (state === "granted") reloadDevices(name);
    }
  };

  const detachListeners = () => {
    Object.entries(permissionObjects).forEach(([name, p]) => {
      p?.removeEventListener("change", onPermissionChange(name as MediaDeviceType));
    });

    navigator.mediaDevices.removeEventListener("devicechange", handleDeviceChange);
  };

  const permissionStatePolling = (mediaType: MediaDeviceType) => {
    const permissionName = mapPermissionName(mediaType);
    if (!permissionName) return;
    navigator.permissions.query({ name: permissionName as unknown as any })
      .then((permission) => {
        setStates((prev) => ({ ...prev, [mediaType]: permission.state }));
      })
      .catch((err) => null);
  };

  useEffect(() => {
    if (navigator?.permissions?.query) { // permission-API compatible browser
      fetchPermissionObjects(mediaTypes, onPermissionChange).then((permissionObjects) => {
        setPermissionObjects(permissionObjects.reduce((acc, p, i) => ({ ...acc, [mediaTypes[i]]: p }), {}));
        // firefox does not support permission API for camera and microphone (permissionObjects = [null, null])
        setStates(permissionObjects.reduce((acc, p, i) => ({ ...acc, [mediaTypes[i]]: p?.state || "prompt" }), {}));

        requestGenericMediaPermission(mediaTypes).then((res) => {
          setStates((prev) => ({
            ...prev,
            ...mediaTypes.reduce((acc, m, i) => ({ ...acc, [m]: res[i].state }), {}),
          }));
          setDevices((prev) => ({
            ...prev,
            ...mediaTypes.reduce((acc, m, i) => ({ ...acc, [m]: res[i].devices }), {}),
          }));
          // console.log("genericPermRes", res);
        }).catch((err) => {
          if (err.name === "NotFoundError") {
            setStates((prev) => ({ ...prev, VIDEO: "no-device" }));
            requestSpecificMediaPermission("AUDIO").then(({ state: s2, devices: d2 }) => {
              setStates((prev) => ({ ...prev, AUDIO: s2 }));
              setDevices((prev) => ({ ...prev, AUDIO: d2 }));
            }).catch((err) => {
              // console.log("specificPermErr", err);
            });
          }
        });
      })
        .catch((err) => {
          // console.log("fetchPermErr", err);
        });
    } else {
      // ask directly
      requestGenericMediaPermission(mediaTypes).then((states) => {
        setStates(states.reduce((acc, s, i) => ({ ...acc, [mediaTypes[i]]: s }), {}));

        fetchDevices(mediaTypes).then((devices) => {
          setDevices(devices.reduce((acc, d, i) => ({ ...acc, [mediaTypes[i]]: d }), {}));
        });
      });
    }

    navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);

    return () => detachListeners();
  }, []);

  useEffect(() => {
    if (!navigator?.permissions) return;
    if (!("onwebkitmouseforceup" in document)) return; // safari fails this (it's one of their non w3c-standard events)
    // safari, in its infinite wisdom, has a bug that causes permission API onchange event not to fire, since 3+ years
    // Thank you, Steve Jobs, very cool!
    const polling = setInterval(() => {
      Promise.all(mediaTypes.map((mediaType) => permissionStatePolling(mediaType)));
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => { clearInterval(polling); };
  }, []);

  const requestMediaStream = async (mediaType: MediaDeviceType, deviceId?: string) => {
    const mediaConstraint = deviceId ? { deviceId: { exact: deviceId } } : true;
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ [mapUserMedia(mediaType)]: mediaConstraint });
      return stream;
    } catch (err) {
      if (err?.name === "OverconstrainedError") return undefined; // Safari: asking for a device that doesn't exist
      setStates((prev) => ({ ...prev, [mediaType]: "denied" as const }));
      return undefined;
    }
  };

  return {
    states,
    devices,
    requestMediaStream,
  };
};

export default useNavigatorMediaDevices;
