import React, {
  useEffect, useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo, faMicrophone, faSort, faCheck,
  faMicrophoneSlash,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons";
import { Listbox } from "@headlessui/react";
import clsx from "clsx";
import { checkOverflow } from "@audacia-hq/shared/utils";

import Select, { Option } from "./Select";

interface Props {
  mediaType: "AUDIO" | "VIDEO";
  onChange: (deviceId: string) => void;
  initialValue?: string;
  nullLabel?: string;
  devices: MediaDeviceInfo[];
  placeHolderText?: string;
  className?: string;
  error?: string;
  disabled?: boolean;
  dataPW?: string;
}

const renderMediaIcon = (mediaType: "AUDIO" | "VIDEO", slashed?: boolean) => {
  switch (mediaType) {
    case "AUDIO":
      return <FontAwesomeIcon icon={slashed ? faMicrophoneSlash : faMicrophone} className="text-gray-500" />;
    case "VIDEO":
      return <FontAwesomeIcon icon={slashed ? faVideoSlash : faVideo} className="text-gray-500" />;
    default:
      return <></>;
  }
};

const DevicePicker: React.FC<Props> = ({
  mediaType, onChange, initialValue, nullLabel, devices, placeHolderText, className, error, disabled, dataPW,
}) => {
  const [deviceId, setDeviceId] = useState<string | undefined>((
    !initialValue
    && nullLabel) ? null : (initialValue || devices?.[0]?.deviceId || ""));
  const relativeRef = React.useRef<HTMLElement>(null);
  const boundsRef = React.useRef<HTMLElement>(null);
  const [optionsListCN, setOptionsListCN] = useState<string>("");

  useEffect(() => {
    if (!devices?.length || (deviceId === null && nullLabel)) return;
    const found = devices?.find((d) => d.deviceId === deviceId);
    if (!found) {
      setDeviceId(devices[0].deviceId);
      onChange(devices[0].deviceId);
    }
  }, [deviceId, devices, nullLabel]);

  const renderSelected = (deviceId) => (
    <div className="whitespace-nowrap overflow-hidden text-ellipsis w-full">
      <div className="w-6 inline-block">
        {renderMediaIcon(mediaType, deviceId === null)}
      </div>
      {deviceId === null && nullLabel
        ? nullLabel
        : devices.find((device) => device.deviceId === deviceId)?.label || placeHolderText}
    </div>
  );

  return (
    <div className={className || ""}>
      <Listbox
        value={deviceId}
        onChange={(value) => {
          setDeviceId(value);
          onChange(value);
        }}
      >
        <div
          className="relative"
          ref={(elt) => {
            if (elt) {
              relativeRef.current = elt;
              const bounds = elt.closest(".overflow-hidden, .overflow-y-hidden, body");
              if (bounds) boundsRef.current = (bounds as HTMLElement);
            }
          }}
        >
          <Listbox.Button
            className={`relative w-full border
            ${error ? "border-red-500" : "border-gray-300"} 
            rounded-lg shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm 
            ${disabled ? "bg-gray-100 bg-clip-padding opacity-70 text-gray-600 cursor-not-allowed" : "bg-white cursor-pointer"}`}
            data-pw={dataPW}
          >
            <span className="flex items-center h-5">
              {((deviceId || (nullLabel && deviceId === null)) && renderSelected ? renderSelected(deviceId) : deviceId)}
            </span>
            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <FontAwesomeIcon icon={faSort} />
            </span>
          </Listbox.Button>
          <Listbox.Options
            ref={(elt) => {
              if (elt) {
                const res = checkOverflow(elt, boundsRef.current);
                if (res.isOverflowing && res.overflowPx.bottom > 0) {
                  // fits on top?
                  if (res.overflowPx.top + elt.clientHeight + relativeRef.current.clientHeight < 0) {
                    setOptionsListCN("bottom-full");
                    return;
                    // elt.style.bottom = "100% !important";
                  }
                  // elt.style.top = `${-res.overflowPx.top - 8}px`;
                  setOptionsListCN("-top-full");
                  // setOptionsListStyle({ top: `${-res.overflowPx.top - 8}px !important` });
                  return;
                }
              }
              setOptionsListCN("");
            }}
            className={`absolute z-10 my-1 ${optionsListCN} w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base 
              ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`}
            data-pw={dataPW ? `${dataPW}-options` : undefined}
          >
            {nullLabel && (
              <Option text={nullLabel} key="null" value={null} />
            )}
            {devices && devices.map((device) => (
              <Option text={device.label} key={device.deviceId} value={device.deviceId} />
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    </div>

  );
};

export default DevicePicker;
