/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import clsx from "clsx";
import { generateColor } from "@audacia-hq/shared/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

interface AvatarProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  name?: string;
  uid?: string;
  src?: string;
  lighten?: boolean | number;
}

/** Minimal Avatar wrapper to fallback to text */
const Avatar = ({
  name, uid, src, lighten, ...props
}: AvatarProps) => {
  if (src) {
    return (
      <div
        className={clsx("overflow-hidden", props.className)}
        style={{
          width: props.width,
          height: props.height,
          ...props.style,
        }}
      >
        <img
          className="w-full h-full object-cover"
          src={src}
          alt={`avatar ${name || uid}`}
          aria-label={`avatar ${name || uid}`}
        />
      </div>

    );
  }

  const generateLetters = (t: string) => {
    const p = t.split(" ");
    return (p.length > 1 && p[0] && p[1] ? p[0].at(0) + p[1].at(0) : p[0].substring(0, 2)).toUpperCase();
  };

  return (
    <div
      className={clsx("text-lg text-white", props.className)}
      aria-label={`avatar ${name || uid}`}
      style={{
        backgroundColor: generateColor(
          uid || name || "",
          typeof lighten === "number" ? 1 - lighten : lighten ? 0.3 : 1,
        ),
        containerName: "avatar",
        containerType: "size",
        // Image compatibility
        width: props.width,
        height: props.height,
        ...props.style,
      }}
    >
      <div className="flex w-full h-full justify-center items-center text-[30cqh]">
        {name ? generateLetters(name) : (<FontAwesomeIcon icon={faQuestion} />)}
      </div>
    </div>
  );
};

export default Avatar;
