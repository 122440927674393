/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs, { Dayjs } from "dayjs";
import React, {
  useCallback, useState,
} from "react";
import { Rnd } from "react-rnd";

import { useCalendar } from "./CalendarContext";
import { CalendarRange } from "./Calendar";

interface Range {
  start: Dayjs;
  end: Dayjs;
}

interface Props {
  className: string;
  range: CalendarRange;
  resizing?: ResizeDirections,
  disableDragging?: boolean;
  onModify: (before: Range, after: Range) => void;
  onClick?: () => void;
  clickDelayMs?: number;
  onInteraction?: (isInteracting: boolean) => void;
}

interface ResizeDirections {
  top?: boolean;
  bottom?: boolean;
  left?: boolean;
  right?: boolean;
}

const defaultResizing = {
  top: false,
  bottom: false,
  left: false,
  right: false,
  topLeft: false,
  topRight: false,
  bottomLeft: false,
  bottomRight: false,
};

const CalendarDraggable: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  range,
  resizing,
  disableDragging,
  onModify,
  onClick,
  clickDelayMs,
  onInteraction,
}) => {
  const {
    days, dayPx, hourPx, getClickDate, boundsElement,
  } = useCalendar();
  const { start, end } = range;
  const [deleting, setDeleting] = useState<boolean>(false);

  const [dragStartTime, setDragStartTime] = useState<Dayjs>();

  const toggleInteraction = (isInteracting: boolean) => {
    if (!onInteraction) return;
    onInteraction(isInteracting);
  };

  const onDragStart = (e, data) => {
    toggleInteraction(true);
    setDragStartTime(dayjs());
  };

  const onDragStop = useCallback((e, data) => {
    if (!dragStartTime) return;

    const day = Math.round(data.x / dayPx);
    const minutes = Math.round(data.y / (hourPx / 4)) * 15;

    if (day === 0 && minutes === 0) {
      if (dayjs().diff(dragStartTime, "millisecond") < (clickDelayMs || 1000)) onClick();
    } else {
      onModify(
        { start, end },
        { start: start.add(day, "day").add(minutes, "minute"), end: end.add(day, "day").add(minutes, "minute") },
      );
      toggleInteraction(false);
    }
  }, [onModify, dayPx, hourPx, start, end, dragStartTime]);

  const onResize = (e, direction, ref, delta, position) => {
    setDragStartTime(undefined); // cancel future drag stop (since mobile triggers both)
    const date = getClickDate(e);
    const dateTime = date.hour() * 60 + date.minute();
    if (direction === "top") {
      setDeleting(dateTime >= end.hour() * 60 + end.minute());
    } else {
      setDeleting(dateTime <= start.hour() * 60 + start.minute());
    }
  };

  const onResizeStop = useCallback((e, direction, ref, delta, position) => {
    if (deleting) {
      if (direction === "top") {
        onModify({ start, end }, { start: end, end });
        toggleInteraction(false);
      } else {
        onModify({ start, end }, { start, end: start });
        toggleInteraction(false);
      }
      return;
    }

    if (direction === "top") {
      const resizeDate = start.subtract(Math.round(delta.height / (hourPx / 4)) * 15, "minute");
      onModify({ start, end }, { start: resizeDate, end });
      toggleInteraction(false);
    } else {
      const resizeDate = end.add(Math.round(delta.height / (hourPx / 4)) * 15, "minute");
      onModify({ start, end }, { start, end: resizeDate });
      toggleInteraction(false);
    }
  }, [onModify, deleting, hourPx, start, end]);

  return (
    <Rnd
      onResizeStart={(evt) => {
        evt.stopPropagation();
        toggleInteraction(true);
      }}
      onResize={onResize}
      onResizeStop={onResizeStop}
      enableResizing={{ ...defaultResizing, ...resizing }}
      resizeGrid={[1, hourPx / 4]}
      bounds={boundsElement || ".planning-bounds"}
      disableDragging={disableDragging}
      dragAxis={days.length === 1 ? "y" : "both"}
      dragGrid={[dayPx, hourPx / 4]}
      onDragStart={onDragStart}
      onDragStop={onDragStop}
      className={`pointer-events-auto ${className || ""} ${deleting ? "opacity-50" : ""}`}
    >
      {children}
    </Rnd>
  );
};

export const captureDragProps = {
  onTouchStartCapture: (e) => e.stopPropagation(),
  onTouchEndCapture: (e) => e.stopPropagation(),
  onMouseDown: (e) => e.stopPropagation(),
  onMouseUp: (e) => e.stopPropagation(),
};

export default CalendarDraggable;
