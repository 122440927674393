import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import {
  dayjsifyPlanning, estimateSlottedItemStart, spliceSlots, truncateSlotsLeft, truncateSlotsRight,
} from "@audacia-hq/shared/utils";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import isoWeek from "dayjs/plugin/isoWeek";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Subject } from "rxjs";

import {
  ConsultationRange,
  PlanningQueueSlot, PlanningRange, PlanningSlot,
} from "../models/planning";
import { SlottedQueueItem } from "../models/expert";
import { GET_PLANNING_EFFECTIVE, GET_CONSULTATIONS } from "../graphql/query";
import {
  PlanningMutations, SET_EFFECTIVE_RANGES,
} from "../graphql/mutations";

import { useExpert } from "./ExpertContext";
import { useWS, WSMessage } from "./WSContext";

dayjs.extend(timezone);
dayjs.extend(isoWeek);

interface PlanningContextType {
  planningUpdatedFeed: Subject<"UPDATE"|"RESET">;
  consultationsUpdatedFeed: Subject<boolean>;
  getPlanning: (from: Dayjs, to: Dayjs) => Promise<PlanningRange[]>;
  getConsultations: (from: Dayjs, to: Dayjs) => Promise<ConsultationRange[]>;
  slottedQueueItems: SlottedQueueItem[];
  updatePlanning: (ranges: PlanningRange[]) => void;
  isDailyPlanningOpen: boolean,
  setIsDailyPlanningOpen: (isOpen: boolean) => void,
  hasDailyPlanning: boolean;
  currentTime: Dayjs;
  refreshCurrentTime: () => void;
  startOfDay?: Dayjs;
  endOfDay: Dayjs;
  currentSlot: PlanningSlot;
  currentQueueSlot: PlanningQueueSlot;
  nextSlot: PlanningSlot;
}

const PlanningContext = React.createContext<PlanningContextType>({
  planningUpdatedFeed: undefined,
  consultationsUpdatedFeed: undefined,
  getPlanning: () => undefined,
  getConsultations: () => undefined,
  slottedQueueItems: [],
  updatePlanning: () => undefined,
  isDailyPlanningOpen: false,
  setIsDailyPlanningOpen: () => undefined,
  hasDailyPlanning: true,
  currentTime: undefined,
  refreshCurrentTime: () => undefined,
  startOfDay: undefined,
  endOfDay: undefined,
  currentSlot: undefined,
  currentQueueSlot: undefined,
  nextSlot: undefined,
});

const PlanningProvider: React.FC<{ children: React.ReactNode; }> = ({ children }) => {
  const { expert } = useExpert();

  const ws = useWS();

  const [getPlanningEffective] = useLazyQuery(GET_PLANNING_EFFECTIVE);
  const [getConsultationQuery] = useLazyQuery(GET_CONSULTATIONS);

  const [rangeUpdates, setRangeUpdates] = useState<PlanningRange[]>([]); // buffer for planning updates
  const [setEffectiveRanges] = useMutation<PlanningMutations>(SET_EFFECTIVE_RANGES);

  const [userTimezone] = useState<string>(dayjs.tz.guess());
  const [currentTime, setCurrentTime] = useState<Dayjs>(dayjs());

  const [planningUpdatedFeed] = useState<Subject<"UPDATE"|"RESET">>(new Subject<"UPDATE"|"RESET">());
  const [consultationsUpdatedFeed] = useState<Subject<boolean>>(new Subject<boolean>());

  const [slottedQueueItems, setSlottedQueueItems] = useState<SlottedQueueItem[]>([]);

  const [planningDays, setPlanningDays] = useState<{ [day: string]: PlanningRange }>({});
  const [consultationDays, setConsultationDays] = useState<{ [day: string]: ConsultationRange }>({});

  const [isDailyPlanningOpen, setIsDailyPlanningOpen] = useState<boolean>(false);
  const [hasDailyPlanning, setHasDailyPlanning] = useState<boolean>(true);
  const [startOfDay, setStartOfDay] = useState<Dayjs>();
  const [endOfDay, setEndOfDay] = useState<Dayjs>(dayjs().add(1, "day").startOf("day"));

  const [currentQueueSlot, setCurrentQueueSlot] = useState<PlanningQueueSlot>();
  const [currentSlot, setCurrentSlot] = useState<PlanningSlot>();
  const [nextSlot, setNextSlot] = useState<PlanningSlot>();

  const getQueueSlots = (isoDates: string[]) => isoDates.sort()
    .reduce((aggr, date) => [...aggr, ...(planningDays[date]?.queueSlots || [])], []);

  const getCurrentSlot = (today: string, tomorrow: string, now: Dayjs) => [
    ...(planningDays[today]?.slots || []),
    ...(planningDays[tomorrow]?.slots || [])]
    .find((s) => s.end.isAfter(now) && !s.start.isAfter(now)) || undefined;

  const getNextSlot = (today: string, tomorrow: string, now: Dayjs) => [...(planningDays[today]?.slots || []), ...(planningDays[tomorrow]?.slots || [])]
    .find((s) => s.start.isAfter(now)) || undefined;

  const getNightSlots = (queueSlots : PlanningQueueSlot[], from: Dayjs, to: Dayjs) => {
    const { nightSlots, lastEnd } = queueSlots.reduce((aggr, qs) => {
      if (qs.start.isAfter(aggr.lastEnd)) {
        return {
          lastEnd: qs.end,
          nightSlots: [...aggr.nightSlots, { start: aggr.lastEnd, end: qs.start }],
        };
      }
      return { ...aggr, lastEnd: qs.end };
    }, { lastEnd: from, nightSlots: [] });
    return [...nightSlots, ...(lastEnd.isBefore(to) ? [{ start: lastEnd, end: to }] : [])];
  };

  const getBoundSlots = (today: string, tomorrow: string, now: Dayjs, dayEnd: Dayjs) => [
    ...(planningDays[today]?.slots || []),
    ...(planningDays[tomorrow]?.slots || [])]
    .filter((s) => s.end.isAfter(now) && !dayEnd?.isBefore(s.end)) || [];

  const refreshSlottedQueueItems = useCallback(() => {
    const now = dayjs();
    const today = now.startOf("day").toISOString();
    const yesterday = now.startOf("day").subtract(1, "day").toISOString();
    const tomorrow = now.startOf("day").add(1, "day").toISOString();
    const queueSlots = getQueueSlots([yesterday, today, tomorrow]);
    const nightSlots = getNightSlots(queueSlots, dayjs(yesterday), dayjs(tomorrow).add(1, "day"));
    const dayStart = nightSlots.reduce((aggr, ns) => (ns.start.isBefore(now) ? ns.end : aggr), undefined);
    const dayEnd = nightSlots.find((ns) => ns.start.isAfter(now))?.start || dayjs(tomorrow);
    const currentSlot = getCurrentSlot(today, tomorrow, now);
    const nextSlot = getNextSlot(today, tomorrow, now);

    setCurrentQueueSlot(queueSlots.find((qs) => qs.start.isBefore(now) && qs.end.isAfter(now)));
    setCurrentSlot(currentSlot);
    setNextSlot(nextSlot);
    setStartOfDay(dayStart);
    setEndOfDay(dayEnd);

    if (expert?.queue?.items.length === 0) {
      setSlottedQueueItems([]);
      return;
    }
    const boundSlots = getBoundSlots(today, tomorrow, now, dayStart?.isBefore(now) ? dayEnd : now);

    const slottedItems = estimateSlottedItemStart(
      expert?.queue?.items,
      boundSlots,
      {
        timeBetween: 120,
        minimumOverlap: 300,
        maximumExcess: 900,
        startFrom: now,
      },
    );
    setSlottedQueueItems(slottedItems as SlottedQueueItem[]);
  }, [planningDays, expert?.queue?.items]);

  const handlePlanningUpdatedEvent = (msg: WSMessage) => {
    if (msg.subject !== expert?.expertUid) return;
    const payload = JSON.parse(msg.payload);
    const updatedPlanningDays = planningRangeToDays(dayjsifyPlanning(payload) as PlanningRange);
    setPlanningDays((prev) => updatedPlanningDays.reduce<{ [day: string]: PlanningRange }>((aggr, up) => {
      const found = Object.values(aggr).find((d) => !d.from.isAfter(up.from) && !d.to.isBefore(up.to));
      return found
        ? {
          ...aggr,
          [found.from.toISOString()]: {
            ...found,
            slots: spliceSlots(found.slots, up.slots, up.from, up.to),
            queueSlots: spliceSlots(found.queueSlots, up.queueSlots, up.from, up.to),
          },
        } as { [day: string]: PlanningRange }
        : aggr;
    }, prev));
  };

  const handleConsultationFinished = (msg: WSMessage) => {
    const consultation = JSON.parse(msg.payload);
    const localDay = dayjs(consultation.startDate).startOf("day");
    setConsultationDays((prev) => ({
      ...prev,
      [localDay.toISOString()]: {
        from: localDay,
        to: localDay.add(1, "day"),
        consultations: [
          {
            ...consultation,
            start: dayjs(consultation.startDate),
            end: dayjs(consultation.endDate),
            duration: parseInt(consultation.duration, 10),
            maxDuration: consultation.maxDuration ? parseInt(consultation.maxDuration, 10) : undefined,
          },
          ...(prev[localDay.toISOString()]?.consultations || []),
        ],
      },
    }));
  };

  useEffect(() => {
    const refresh = setInterval(() => {
      const now = dayjs();
      setCurrentTime(now);
    }, 150000 /* 2.5m */);
    return () => clearInterval(refresh);
  }, []);

  useEffect(() => {
    refreshSlottedQueueItems();
  }, [currentTime]);

  useEffect(() => {
    planningUpdatedFeed.next("UPDATE");
    if (!expert?.queue?.items) return;
    refreshSlottedQueueItems();
    if (planningDays[dayjs().startOf("day").toISOString()]) {
      setHasDailyPlanning(!!planningDays[dayjs().startOf("day").toISOString()].slots.length);
    }
  }, [planningDays]);

  useEffect(() => {
    consultationsUpdatedFeed.next(true);
  }, [consultationDays]);

  useEffect(() => {
    if (!expert?.queue?.items) return;
    refreshSlottedQueueItems();
  }, [expert?.queue?.items, refreshSlottedQueueItems]);

  useEffect(() => {
    const sub = ws.subscribe((msg: WSMessage) => {
      if (msg.event === "ws.reconnected") {
        invalidateFuture(); // remove future planning days from internal state, so they are re-fetched
        planningUpdatedFeed.next("RESET");
        return;
      }
      if (msg.event === "expert.planning.updated") {
        handlePlanningUpdatedEvent(msg);
      } else if (msg.event === "expert.consultation.finished") {
        handleConsultationFinished(msg);
      }
    });
    return () => sub.unsubscribe();
  }, [ws, expert?.expertUid]);

  const planningRangeToDays = (range: PlanningRange) => {
    const toDaysRecur = (remainingRange: PlanningRange): PlanningRange[] => {
      if (!remainingRange) return [];
      if (!remainingRange.from.isBefore(remainingRange.to)) return [];

      const startOfDay = remainingRange.from;
      const endOfDay = dayjs.min(remainingRange.from.startOf("day").add(1, "day"), remainingRange.to);
      return [
        {
          from: startOfDay,
          to: endOfDay,
          slots: truncateSlotsRight(remainingRange.slots, endOfDay) as PlanningSlot[],
          queueSlots: truncateSlotsRight(remainingRange.queueSlots, endOfDay) as PlanningQueueSlot[],
        },
        ...toDaysRecur({
          from: endOfDay,
          to: remainingRange.to,
          slots: truncateSlotsLeft(remainingRange.slots, endOfDay) as PlanningSlot[],
          queueSlots: truncateSlotsLeft(remainingRange.queueSlots, endOfDay) as PlanningQueueSlot[],
        }),
      ];
    };
    return toDaysRecur(range);
  };

  const consultationRangeToDays = (range: ConsultationRange) => {
    const toDaysRecur = (remainingRange: ConsultationRange): ConsultationRange[] => {
      if (!remainingRange) return [];
      if (!remainingRange.from.isBefore(remainingRange.to)) return [];

      const startOfDay = remainingRange.from;
      const endOfDay = dayjs.min(remainingRange.from.startOf("day").add(1, "day"), remainingRange.to);

      return [
        {
          from: startOfDay,
          to: endOfDay,
          consultations: remainingRange.consultations
            .filter((c) => c.start.isBefore(endOfDay) && c.end.isAfter(startOfDay)),
        },
        ...toDaysRecur({
          from: endOfDay,
          to: remainingRange.to,
          consultations: remainingRange.consultations
            .filter((c) => c.start.isBefore(remainingRange.to) && c.end.isAfter(endOfDay)),
        }),
      ];
    };
    return toDaysRecur(range);
  };

  const rangeToDayList = (from: Dayjs, to: Dayjs) => {
    const mkDaysRecur = (from: Dayjs, to: Dayjs): string[] => {
      if (!from.isBefore(to)) return [];
      return [
        from.startOf("day").toISOString(),
        ...mkDaysRecur(from.startOf("day").add(1, "day"), to),
      ];
    };
    return mkDaysRecur(from, to);
  };

  const dayListToRanges = (days: string[]) => days.reduce(({ toMerge, res }, d, idx) => {
    const day = dayjs(d);
    const canMerge = toMerge.length === 0 || toMerge.at(-1)?.add(1, "day").isSame(day);
    const newRes = !canMerge ? [...res, { from: toMerge[0], to: toMerge.at(-1).add(1, "day") }] : res;

    if (idx === days.length - 1) {
      return {
        toMerge: [],
        res: [
          ...newRes,
          ...(canMerge ? [{ from: toMerge[0] || day, to: day.add(1, "day") }] : [{ from: day, to: day.add(1, "day") }]),
        ],
      };
    }

    return { toMerge: canMerge ? [...toMerge, day] : [day], res: newRes };
  }, { toMerge: [], res: [] });

  const getPlanning = useCallback(async (from: Dayjs, to: Dayjs) => {
    const days = rangeToDayList(from, to);
    const missingDays = dayListToRanges(days.filter((d) => !planningDays[d])).res;
    if (missingDays.length === 0) return days.map((d) => planningDays[d]);

    const fetched = await fetchPlanning(from, to);
    const newPlanningDays = planningRangeToDays(fetched)
      .reduce((aggr, d) => ({
        ...aggr,
        [d.from.toISOString()]: d,
      }), planningDays);

    setPlanningDays(newPlanningDays);
    return days.map((d) => newPlanningDays[d]);
  }, [planningDays]);

  const fetchPlanning = async (from: Dayjs, to: Dayjs) => getPlanningEffective({
    variables: {
      from: from.toISOString(),
      to: to.toISOString(),
      timezone: userTimezone,
    },
    fetchPolicy: "no-cache",
  }).then((res) => ({
    from,
    to,
    slots: res.data.planningEffective.slots.map((s) => ({ ...s, start: dayjs(s.start), end: dayjs(s.end) })),
    queueSlots: res.data.planningEffective.queueSlots.map((s) => ({ ...s, start: dayjs(s.start), end: dayjs(s.end) })),
  }));

  const getConsultations = useCallback(async (from: Dayjs, to: Dayjs) => {
    const days = rangeToDayList(from, to);
    const missingDays = dayListToRanges(days.filter((d) => !consultationDays[d])).res;
    if (missingDays.length === 0) return days.map((d) => consultationDays[d]);

    const fetched = await fetchConsultations(from, to);
    const newConsultationDays = consultationRangeToDays(fetched)
      .reduce((aggr, d) => ({
        ...aggr,
        [d.from.toISOString()]: d,
      }), consultationDays);

    setConsultationDays(newConsultationDays);
    return days.map((d) => newConsultationDays[d]);
  }, [consultationDays]);

  const fetchConsultations = async (from: Dayjs, to: Dayjs) => getConsultationQuery({
    variables: {
      filters: {
        from: from.toISOString(),
        to: to.toISOString(),
      },
    },
    fetchPolicy: "no-cache",

  }).then((res) => ({
    from,
    to,
    consultations: res.data.consultations.data.map((c) => ({
      ...c, start: dayjs(c.startDate), end: dayjs(c.endDate),
    })),
  }));

  const bufferPlanningUpdate = (ranges: PlanningRange[]) => {
    setRangeUpdates((prev) => [...prev, ...ranges]);
  };

  const updatePlanning = (ranges: PlanningRange[]) => {
    setEffectiveRanges({
      variables: {
        ranges,
        timezone: userTimezone,
      },
    }).then(async () => {
      // fetch extra days (update impacts night / queue slots)
      const fetchFrom = dayjs.min(ranges.map((r) => r.from)).startOf("day").subtract(1, "day");
      const fetchTo = dayjs.max(ranges.map((r) => r.to)).startOf("day").add(2, "day");

      const range = await fetchPlanning(fetchFrom, fetchTo);

      setPlanningDays((prev) => planningRangeToDays(range).reduce((aggr, d) => ({
        ...aggr,
        [d.from.toISOString()]: d,
      }), prev));
    });
  };

  const invalidateFuture = () => {
    const today = dayjs().startOf("day").toISOString();
    setPlanningDays((prev) => Object.entries(prev).reduce((aggr, [k, v]) => {
      // date string comparison
      if (k >= today) return aggr;
      return { ...aggr, [k]: v };
    }, {}));
    setConsultationDays((prev) => Object.entries(prev).reduce((aggr, [k, v]) => {
      // date string comparison
      if (k >= today) return aggr;
      return { ...aggr, [k]: v };
    }, {}));
  };

  useEffect(() => {
    if (rangeUpdates.length === 0) return;
    updatePlanning(rangeUpdates);
    setRangeUpdates((prev) => prev.slice(rangeUpdates.length, prev.length));
  }, [rangeUpdates]);

  const value = useMemo(
    () => (
      {
        planningUpdatedFeed,
        consultationsUpdatedFeed,
        getPlanning,
        getConsultations,
        slottedQueueItems,
        updatePlanning: bufferPlanningUpdate,
        isDailyPlanningOpen,
        setIsDailyPlanningOpen: (isOpen: boolean) => setIsDailyPlanningOpen(isOpen),
        hasDailyPlanning,
        currentTime,
        refreshCurrentTime: () => setCurrentTime(dayjs()),
        startOfDay,
        endOfDay,
        currentSlot,
        currentQueueSlot,
        nextSlot,
      }
    ),
    [
      planningUpdatedFeed, consultationsUpdatedFeed,
      getPlanning, getConsultations, slottedQueueItems,
      isDailyPlanningOpen, setIsDailyPlanningOpen, hasDailyPlanning,
      currentTime, startOfDay, endOfDay, currentSlot, currentQueueSlot, nextSlot,
    ],
  );

  return (
    <PlanningContext.Provider value={value}>
      {children}
    </PlanningContext.Provider>
  );
};

const usePlanning = () => React.useContext(PlanningContext);

export default PlanningContext;
export { PlanningProvider, usePlanning };
