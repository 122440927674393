/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs, { Dayjs } from "dayjs";
import React, {
  useCallback, useEffect, useRef, useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Subject } from "rxjs";
import { getElementPageOffset } from "@audacia-hq/shared/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import { SlotRangeInput, SlotInput } from "./models";
import CalendarHeader from "./CalendarHeader";
import { CalendarProvider } from "./CalendarContext";

export interface Preview {
  start: Dayjs;
  end: Dayjs;
  channels: SlotInput["channels"];
}

export interface PreviewEvent {
  event: "CLEAR" | "DRAG" | "MODAL";
  previews: Preview[];
}

export interface CalendarRange {
  start: Dayjs;
  end: Dayjs;
  [key: string]: any;
}

export interface InternalCalendarProps {
  hourPx: number;
  dayPx: number;
  days: Dayjs[];
  getClickDate: (event) => Dayjs;
  boundsElement: Element;
  currentTime: Dayjs;
}

interface Props {
  className?: string;
  currentTime: Dayjs;
  days: Dayjs[];
  loading: boolean;
  setEffectiveRanges: (slot: SlotRangeInput[]) => void;
  handleGoNext: (nbDays: number) => void;
  handleGoPrevious: (nbDays: number) => void;
  handleGoToToday: () => void;
  lockDays?: boolean;
  previewSubject: Subject<PreviewEvent>;
  height?: string;
  defaultChannels: string[];
}

const Calendar: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  loading,
  currentTime,
  days,
  setEffectiveRanges,
  handleGoNext,
  handleGoPrevious,
  handleGoToToday,
  lockDays,
  previewSubject,
  height,
  defaultChannels,
}) => {
  const { t } = useTranslation("planning");
  const container = useRef<HTMLDivElement>(null);

  // const [currentTime, setCurrentTime] = useState<Dayjs>(dayjs());
  const boundsRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const refreshCurrentTime = setInterval(() => {
  //     setCurrentTime(currentDate);
  //   }, 2 * 2000);

  //   return () => clearInterval(refreshCurrentTime);
  // }, [currentDate]);

  const hourPx = 72;

  const [isSelecting, setIsSelecting] = useState<Dayjs>();
  const [isSelectingTime, setIsSelectingTime] = useState<Dayjs>();
  const [moveTimeout, setMoveTimeout] = useState(null);

  useEffect(() => {
    autoScroll();
  }, [container]);

  const autoScroll = useCallback(() => {
    if (!container?.current) return;
    const offset = (currentTime.diff(currentTime.startOf("day"), "hour") - 1) * hourPx;
    container.current.scrollTo(0, offset);
  }, [currentTime, container]);

  const getSelectionRanges = (dateClick, dateRelease) => {
    const { fromDay, toDay } = dateRelease.isAfter(dateClick)
      ? { fromDay: isSelecting.startOf("day"), toDay: dateRelease.startOf("day") }
      : { fromDay: dateRelease.startOf("day"), toDay: isSelecting.startOf("day") };

    const [fromMinutes, toMinutes] = [
      dateClick.diff(dateClick.startOf("day"), "minute"),
      dateRelease.diff(dateRelease.startOf("day"), "minute"),
    ].sort((a, b) => a - b);

    if (fromMinutes === toMinutes) return [];
    const nbDays = toDay.diff(fromDay, "day") + 1;
    const now = dayjs();
    return [...Array(nbDays).keys()].map((k) => ({
      from: fromDay.add(k, "day").add(fromMinutes, "minute"),
      to: fromDay.add(k, "day").add(toMinutes, "minute"),
      slots: [{
        start: fromDay.add(k, "day").add(fromMinutes, "minute"),
        end: fromDay.add(k, "day").add(toMinutes, "minute"),
        channels: defaultChannels,
      }],
    })).filter((r) => !r.to.isBefore(now))
      .map((r) => ({
        ...r,
        from: r.from.isBefore(now) ? now : r.from,
        slots: r.slots.map((s) => ({
          ...s,
          start: s.start.isBefore(now) ? now : s.start,
        })),
      }));
  };

  const getClickDate = useCallback((clickEvent) => {
    const eventX = clickEvent.pageX || clickEvent.touches?.[0]?.pageX;
    const eventY = clickEvent.pageY || clickEvent.touches?.[0]?.pageY;
    if (!boundsRef?.current) return null;
    const targetPageCoords = getElementPageOffset(boundsRef.current);
    const day = days.length === 1 ? days[0] : days[Math.max(
      Math.min(
        Math.floor((eventX - targetPageCoords.left) / Math.round(boundsRef.current.offsetWidth / days.length)),
        days.length - 1,
      ),
      0,
    )];
    const quart = Math.max(
      Math.min(
        Math.round((eventY - targetPageCoords.top) / Math.round(boundsRef.current.offsetHeight / 96)),
        95,
      ),
      0,
    );
    return day.add(quart * 15, "minute");
  }, [boundsRef, days]);

  const handleSelectingMove = (evt) => {
    if (!isSelecting || moveTimeout) return;
    setMoveTimeout(setTimeout(() => setMoveTimeout(null), 25));
    const ranges = getSelectionRanges(isSelecting, getClickDate(evt));
    previewSubject.next({
      event: "DRAG",
      previews: ranges.reduce((aggr, r) => [...aggr, r.slots[0]], []),
    });
  };

  const handleStartSelecting = (evt) => {
    if (evt.button !== 0 || !days?.length) return;
    setIsSelecting(getClickDate(evt));
    setIsSelectingTime(dayjs());
    previewSubject.next({
      event: "CLEAR",
      previews: [],
    });
  };

  const handleStopSelecting = (evt) => {
    if (evt.button !== 0 || !days?.length) return;
    if (!isSelecting) return;

    const ranges = getSelectionRanges(isSelecting, getClickDate(evt));
    if (ranges.length) {
      setEffectiveRanges(ranges.map((r) => ({
        from: r.from.toISOString(),
        to: r.to.toISOString(),
        slots: r.slots.map((s) => ({ ...s, start: s.start.toISOString(), end: s.end.toISOString() })),
      })) as SlotRangeInput[]);
    }
    setIsSelecting(undefined);
    previewSubject.next({
      event: "CLEAR",
      previews: [],
    });
  };

  const handleBackgroundClick = useCallback((evt) => {
    const clickDate = getClickDate(evt);

    previewSubject.next({
      event: "MODAL",
      previews: [{
        start: clickDate,
        end: clickDate.add(1, "hour"),
        channels: ["PHONE", "VIDEO", "LIVECHAT"] as SlotInput["channels"],
      }],
    });
    setIsSelecting(undefined);
  }, [currentTime, previewSubject, isSelecting, isSelectingTime]);

  return (
    <div className="flex flex-col flex-nowrap" style={{ height }}>
      <div className="sticky top-0 z-[9] grow-0 shrink-0">
        {days.length > 1 && (
          <CalendarHeader
            className="bg-gray-100"
            handleGoNext={() => handleGoNext(days.length)}
            handleGoPrevious={() => handleGoPrevious(days.length)}
            handleGoToToday={() => { handleGoToToday(); autoScroll(); }}
            currentDate={days[0]}
            lockDays={lockDays}
          />
        )}
        <div
          className="flex-col"
        >
          <div
            className={`bg-white shadow ring-1 ring-black ring-opacity-5 pl-14 ${className || ""}`}
          >

            <div
              className="relative -mr-px border-l border-gray-100 text-sm leading-6 text-gray-500 grid"
              style={{ gridTemplateColumns: `repeat(${days.length}, minmax(0, 1fr)) auto` }}
            >

              {days.map((date, index) => {
                const diff = dayjs().diff(date, "day", true);
                return (
                  <div
                    key={index}
                    className="py-3 flex flex-col items-center justify-center border-r border-gray-100"
                  >
                    <div>
                      <span>
                        {t(`days.${date.day()}`)}
                        {" "}
                        <div className={`inline-block rounded-full aspect-square h-6 content-center text-center
                      ${diff <= 1 && diff >= 0 ? "bg-slate-200" : ""}`}
                        >
                          <span className="items-center justify-center font-medium text-slate-900">
                            {date.date()}
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>

                );
              })}

              {days.length === 1 && !lockDays && (
                <>
                  <div
                    className="absolute top-0 left-0 bottom-0 w-12 flex justify-center items-center cursor-pointer"
                    onClick={() => handleGoPrevious(days.length)}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </div>

                  <div
                    className="absolute top-0 right-0 bottom-0 w-12 flex justify-center items-center cursor-pointer"
                    onClick={() => handleGoNext(days.length)}
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </>
              )}

            </div>
          </div>

        </div>
      </div>

      <div
        ref={container}
        className="calendar-element container-scroll no-scrollbar transition ease-in-out duration-1000
            relative z-[8] grow shrink flex flex-auto flex-col overflow-scroll bg-white "
        /* container-scroll = custom class to determine planning offset */
      >
        <div
          className="flex flex-col max-w-full"
        >
          <div className="flex flex-auto">
            <div className="sticky left-0 z-[7] w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div
              className={`grid flex-auto grid-cols-1 grid-rows-1 planning-bounds ${className || ""}`}
              /* planning-bounds = custom class to determine planning drag/resize bounds */
            >
              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-200"
                style={{ gridTemplateRows: `repeat(48, ${hourPx / 2}px)` }}
              >
                <div>
                  <div className="sticky left-0 z-[7] -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs  text-gray-400" />
                </div>
                <div />
                {new Array(23).fill(0).map((_, hour) => (
                  <React.Fragment key={hour}>
                    <div
                      className="relative select-none"
                    >
                      <div className="sticky left-0 z-[7] -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                        {hour + 1}
                        :00
                      </div>
                    </div>
                    <div />
                  </React.Fragment>
                ))}
              </div>

              {/* Vertical lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid-rows-1 divide-x divide-gray-200 grid border-r border-gray-200"
                style={{ gridTemplateColumns: `repeat(${days.length}, minmax(0, 1fr)) auto` }}
              >
                {days.map((d) => (<div key={d.toISOString()} className="row-span-full" />))}
              </div>

              {/* Events */}

              <div
                className="relative z-[1] col-start-1 col-end-2 row-start-1 grid cursor-copy"
                style={{
                  gridTemplateRows: `repeat(96, ${hourPx / 4}px) auto`,
                  gridTemplateColumns: `repeat(${days.length}, minmax(0, 1fr)) auto`,
                }}
                onMouseDown={handleStartSelecting}
                onTouchStartCapture={handleStartSelecting}
                ref={boundsRef}
              >
                {days.map((day, idx) => (
                  <React.Fragment key={idx}>
                    {day.add(1, "day").isBefore(currentTime) && (
                      <div
                        className="bg-slate-400 bg-opacity-20 row-span-full cursor-default"
                        style={{
                          gridColumnStart: idx + 1,
                        }}

                      />
                    )}
                    {currentTime.isAfter(day) && currentTime.isBefore(day.add(1, "day")) && (
                      <div
                        className="relative bg-slate-400 bg-opacity-20 row-span-full cursor-default"
                        style={{
                          height: `${(currentTime.hour() + (currentTime.minute() / 60)) * hourPx}px`,
                          gridColumnStart: idx + 1,
                        }}

                      >
                        <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-red-500 z-[1] pointer-events-none" />
                      </div>
                    )}
                  </React.Fragment>
                ))}
                <CalendarProvider hourPx={hourPx} days={days} boundsRef={boundsRef} getClickDate={getClickDate} currentTime={currentTime}>
                  {children}
                </CalendarProvider>

                {isSelecting && (
                  <div
                    className="absolute inset-0 z-10"
                    onMouseDown={() => setIsSelecting(undefined)}
                    onMouseUp={(e) => {
                      const clickDate = getClickDate(e);
                      if (clickDate.add(15, "minute").isBefore(currentTime) || !clickDate.isSame(isSelecting)
                      || !isSelectingTime || dayjs().diff(isSelectingTime, "millisecond") > 1000) {
                        handleStopSelecting(e);
                      } else {
                        handleBackgroundClick(e);
                      }
                    }}
                    onMouseMove={handleSelectingMove}
                  />
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Calendar;
